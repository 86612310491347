const HIDDEN_FIELD_DISPLAY_VALUE = "[hidden for privacy]";
export const _HIDDEN_FIELDS: ReadonlyArray<string> = [
  "id_token",
  "access_token",
  "refresh_token",
  "password",
  "password_reset_token",
];

// Logrocket type IRequest is not exported
export type RequestResponseType<T = Record<string, unknown>> = {
  headers: { [header: string]: string | undefined };
  body?: string;
} & T;

function _hasJsonBody<T>(requestOrResponse: RequestResponseType<T>): boolean {
  const headers = requestOrResponse.headers;
  const hasJsonContentType = [headers["Content-Type"], headers["content-type"]].some((value) =>
    value?.startsWith("application/json")
  );
  return hasJsonContentType && !!requestOrResponse.body;
}

function _getSanitizedBody(body: string, excludedFields: ReadonlyArray<string>): string {
  let parsedBody: Record<string, unknown> = {};
  try {
    parsedBody = JSON.parse(body);
  } catch {
    return body;
  }

  excludedFields.forEach((field) => {
    if (parsedBody[field] !== undefined) {
      parsedBody[field] = HIDDEN_FIELD_DISPLAY_VALUE;
    }
  });
  return JSON.stringify(parsedBody);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function requestSanitizer<T>(request: RequestResponseType<T>): RequestResponseType<T> {
  if (request.headers["Authorization"] !== undefined) {
    request.headers["Authorization"] = HIDDEN_FIELD_DISPLAY_VALUE;
  }
  if (_hasJsonBody(request)) {
    request.body = _getSanitizedBody(request.body ?? "", _HIDDEN_FIELDS);
  }
  return request;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function responseSanitizer<T>(response: RequestResponseType<T>): RequestResponseType<T> {
  if (_hasJsonBody(response)) {
    response.body = _getSanitizedBody(response.body ?? "", _HIDDEN_FIELDS);
  }
  return response;
}

export default { requestSanitizer, responseSanitizer };
