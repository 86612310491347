import { getEnv } from "./getEnv";

export function isEnabled(name: string): boolean {
  return getEnv(name) === "true";
}

export default {
  // add flags here following this example:
  // isFooEnabled: isEnabled("ENABLE_FOO"),
  isSentryEnabled: isEnabled("ENABLE_SENTRY"),
  isLogrocketEnabled: isEnabled("ENABLE_LOGROCKET"),
  isAutomatedPreLabellingEnabled: isEnabled("ENABLE_AUTOMATED_PRE_LABELLING"),
  isAutomatedTranscriptionPreLabellingEnabled: isEnabled(
    "ENABLE_AUTOMATED_TRANSCRIPTION_PRE_LABELLING"
  ),
  isAutomatedNERPreLabellingEnabled: isEnabled("ENABLE_AUTOMATED_NER_PRE_LABELLING"),
  isAutomatedPoSTPreLabellingEnabled: isEnabled("ENABLE_AUTOMATED_POST_PRE_LABELLING"),
};

export type FeatureFlag =
  | "TEST_FLAG"
  | "AP_ENABLE_GROUP_ASSESSMENTS"
  | "AP_ENABLE_RACCOON"
  | "AP_ENABLE_RACCOON_TESTING_REPORT"
  | "AP_ENABLE_IMAGE_COLLECTION"
  | "AP_ENABLE_APAPI_AUTH"
  | "AP_ENABLE_HIDDEN_ASSESSMENTS"
  | "AP_ENABLE_ASSESSMENT_LINKED_JOB_MANAGEMENT"
  | "AP_ENABLE_ADDITIONAL_CLASS_CATEGORY"
  | "ALL_ENABLE_MAINTENANCE_PAGE"
  | "AP_ENABLE_DOWNLOADABLE_LABELS_LINK"
  | "KANRI_ENABLE_DEACTIVATION_REACTIVATION_OF_USERS"
  | "KANRI_ENABLE_GLOBAL_NAVIGATION"
  | "AP_ENABLE_CAPTION_TO_IMAGE_MATCHING"
  | "AP_ENABLE_PROJECT_FILTER"
  | "AP_ENABLE_WORD_COUNTER_FOR_FREETEXT"
  | "AP_ENABLE_LIMITING_INPUT_METHOD"
  | "AP_ENABLE_AUDIO_HEURISTICS"
  | "AP_DISABLE_DEPRECATED_PROJECT_TYPES"
  | "TIAI_3830_NEW_USER_ROLES";
