import { ButtonSize, IconCog, State } from "@telusinternationalai/seibun-ui";
import { ComponentType, lazy, LazyExoticComponent } from "react";

const ErrorComponent: unknown = {
  default: function failedLoadingChunk() {
    return (
      <div
        className="sui-fixed sui-top-0 sui-left-0 sui-right-0 w-100 sui-bg-white h-100"
        style={{
          zIndex: 999, // Cant set this through tw
          paddingBottom: "4rem", // Did not work to use class name
        }}
      >
        <State
          icon={<IconCog outline />}
          size={ButtonSize.MEDIUM}
          title={"Failed to load component"}
          description={
            <>
              Please try to <strong>clear your browser cache and try again</strong>. Contact support
              if error persists.
            </>
          }
        />
      </div>
    );
  },
};

async function withRetry<T extends ComponentType<unknown>>(
  componentImport: () => Promise<{ default: T }>,
  name: string,
  retries: number
): Promise<{ default: T }> {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(`has-force-refreshed-${name}`) || "false"
  );
  try {
    const component = await componentImport();
    window.localStorage.removeItem(`has-force-refreshed-${name}`);
    return component;
  } catch (error) {
    if (pageHasAlreadyBeenForceRefreshed) {
      if (process.env.NODE_ENV !== "test") {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      return ErrorComponent as { default: T };
    }

    if (retries > 0) {
      return withRetry(componentImport, name, retries - 1);
    }

    window.localStorage.setItem(`has-force-refreshed-${name}`, "true");
    window.location.reload();
    return {
      default: (function Empty() {
        return null;
      } as unknown) as T, // Show nothing while reloading
    };
  }
}

// Need to be same typing as React.lazy which uses 'any'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function lazyLoadWithRetry<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  name: string
): LazyExoticComponent<T> {
  return lazy(() => withRetry(componentImport, name, 1));
}
