import React, { ReactElement, Suspense, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { State, Loader, ButtonSize } from "@telusinternationalai/seibun-ui";

import networkSanitizers from "./networkSanitizers";
import { getEnv } from "./getEnv";
import featureFlags from "./featureFlags";
import * as serviceWorker from "./serviceWorker";
// Importing tailwind CSS
import "./index.css";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Importing react-toastify CSS
import "react-toastify/dist/ReactToastify.min.css";
// Importing the seibun-ui CSS
import "@telusinternationalai/seibun-ui/dist/seibun-ui.min.css";
// Importing override CSS
import "./override.css";
import lazyWithRetry from "./utilities/lazyWithRetry";

const App = lazyWithRetry(() => import("./App"), "App");

if (featureFlags.isSentryEnabled) {
  // Init Sentry
  Sentry.init({
    dsn: getEnv("SENTRY_DSN"),
    environment: getEnv("SENTRY_ENV"),
    ignoreErrors: [/^Request failed with status code 451$/],
  });
}

if (featureFlags.isLogrocketEnabled) {
  // Init LogRocker
  LogRocket.init(getEnv("LOGROCKET_APP_ID") ?? "", {
    network: {
      requestSanitizer: networkSanitizers.requestSanitizer,
      responseSanitizer: networkSanitizers.responseSanitizer,
    },
  });
  setupLogRocketReact(LogRocket);
}

if (featureFlags.isLogrocketEnabled && featureFlags.isSentryEnabled) {
  // Add logrocket URL to Sentry session
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("logrocketURL", sessionURL);
    });
  });
}

function LoadingComponent(): ReactElement {
  // Using useEffect causes a flicker showing two loading indication for 1-2 milliseconds
  useLayoutEffect(() => {
    const initialLoader = document.getElementById("initial-loader");
    if (initialLoader) {
      initialLoader.remove();
    }
  }, []);
  return (
    <div className="sui-h-96">
      <State icon={<Loader role="status" />} size={ButtonSize.MEDIUM} title="Loading App" />
    </div>
  );
}

ReactDOM.render(
  <Suspense fallback={<LoadingComponent />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
